export default {
  tabs: {
    chart: "Graphique",
    labels: "Étiquette",
    canvas: "Toile",
  },
  title: "Mise en forme",
  "chart-form": {
    title: "Graphique",
    description: "Changez ci-dessous le style de vos graphiques.",
    label: "Étiquette",
    "background-fill": "Remplissage de l'arrière-plan",
    "background-colour": "Couleur de l'arrière-plan",
    gradient: "Dégradé",
    "border-colour": "Couleur de la bordure",
    "border-width": "Largeur de la bordure",
    "point-radius": "Rayon du point",
    "point-style": "Style du point",
    "border-radius": {
      title: "Arrondie de la bordure",
      subtitle: "Changez ci-dessous l'arrondi de vos graphiques.",
      "top-left": "Haut gauche",
      "top-right": "Haut droit",
      "bottom-left": "Bas gauche",
      "bottom-right": "Bas droit",
    },
    legend: {
      title: "Légende",
      subtitle:
        "Changez ci-dessous les paramètres des légendes de vos graphiques.",
    },
  },
  "labels-form": {
    common: {
      "x-axes-font-colour": "Couleur de police des axes X",
      "y-axes-font-colour": "Couleur de police des axes Y",
      "y-axes-font-size": "Taille de police des axes Y",
      "font-size": "Taille de police",
      "font-color": "Couleur de la police",
    },
    title: "Étiquettes",
    subtitle: "Changez ci-dessous les paramètres de vos étiquettes.",
    ticks: {
      title: "Ticks",
      subtitle: "Changez ci-dessous les paramètres de vos étiquettes.",
    },
    axes: {
      title: "Axes",
      subtitle: "Changez ci-dessous les paramètres de vos axes.",
    },
  },
  "canvas-form": {
    title: "Toile",
    subtitle: "Changez ci-dessous les paramètres de vos toiles.",
    "x-grid-lines": "Lignes de grille X",
    "y-grid-lines": "Lignes de grille Y",
    "y-axis-stacking": "Empilement de l'axe Y",
    "x-axis-stacking": "Empilement de l'axe X",
  },
}
