import chartStyling from "./chart-styling"
import documentUpload from "./document-upload"
import exportDialog from "./export"

export default {
  export: exportDialog,
  "chart-styling": chartStyling,
  "document-upload": documentUpload,
  "invalid-size":
    "La taille du fichier est supérieure à 10MB, veuillez réessayer",
  "delete-image": {
    title: "Supprimer une image",
    description: "Clique sur supprimer pour retirer l'image.",
  },
}
