export default {
  title: "Exporter les données",
  subtitle:
    "Télécharger un fichier de feuille de calcul de vos données en format.csv pour pouvoir les manipuler dans votre logiciel de tableur préférée.",
  cancel: "Annuler",
  confirm: "Exporter",
  "no-released": "L'exportation n'est pas encore disponible",
  "with-filter": "Exporter avec les filtres actuels",
  "without-filters": "Exporter tout",
}
