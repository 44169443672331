export default {
  "upload-file": "Télécharger fichier",
  "or-drag-and-drop": "ou glisser-déposer",
  files: "Fichiers",
  img: "JPG, JPEG , PNG jusqu'à 10MB ",
  pdf: "PDF jusqu'à 10MB ",
  csv: "CSV jusqu'à 10MB",
  "pdf-and-img": "PDF,JPG,JPEG,PNG jusqu'à 10MB",
  "invalid-extension": "Extension invalide, veuillez réessayer",
  "allowed-formats": "Formats autorisés",
  "maximum-size": "Jusqu'à %{size} Mb",
  "no-file-selected": "Aucun fichier sélectionné",
  "upload-files": "Chargez un fichier ou glissez et déposez",
}
